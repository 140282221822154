import { Button, makeStyles, MessageBar, MessageBarActions, MessageBarBody, shorthands, tokens } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { removeAlert } from '../../redux/features/app/appSlice';

const useClasses = makeStyles({
    alert: {
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground1,
        backgroundColor: tokens.colorNeutralBackground6,
        ...shorthands.borderColor(tokens.colorNeutralBackground4),
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
    },
});

export const Alerts: React.FC = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const alerts = useAppSelector((state: RootState) => state.app.alerts);

    return (
        <div>
            {alerts.map(({ type, message, actions }, index) => {
                return (
                    <MessageBar
                        intent={type}
                        key={`${index}-${type}`}
                        className={classes.alert}
                    >
                        <MessageBarBody>
                            {message.slice(0, 1000) + (message.length > 1000 ? '...' : '')}
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Button
                                    aria-label="dismiss message"
                                    onClick={() => {
                                        dispatch(removeAlert(index));
                                    }}
                                    icon={<DismissRegular />}
                                    appearance='transparent'
                                />
                            }
                        >
                            {actions?.map(({ name, onClick }, actionIndex) => (
                                <Button key={actionIndex} onClick={onClick}>{name}</Button>
                            ))}
                        </MessageBarActions>
                    </MessageBar>
                );
            })}
        </div>
    );
};
